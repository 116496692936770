import React, { useEffect } from 'react';
import ReactGA from "react-ga4";

import { useLocation } from 'react-router-dom';
import Describer from '../components/Describer/Describer';
import FeatureRow from '../components/FeatureRow/FeatureRow';
import Hero from '../components/Hero/Hero';
import { platformContent, platformDescription } from '../constants';
import { TrackPageView } from '../Analytics';

const Platform = () => {
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;

    if (hash) {
      const elementId = hash.replace('#', '');
      const element = document.getElementById(elementId);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }

    ReactGA.initialize("G-L9E3YME0X0");
  }, [location.hash]);


  return (
    <>
      <Hero
        type={'platform'}
        header={'Automations, Integrations, and Dashboards'}
        subHeader={
          'Unlimited eligibility and benefits verification checks to maximize your payouts'
        }
        hasBtn={false}
      />
      <FeatureRow content={platformContent} />

      {platformDescription.map((_, index) => {
        return (
          <Describer
            key={index}
            type={index % 2 === 1 ? 'RIGHT' : 'LEFT'}
            hasBGPatterns={index % 2 === 0}
            header={_.header}
            label={_.label}
            description={_.description}
            hasBtn={false}
            image={_.image}
            id={_.type}
          />
        );
      })}
    </>
  );
};

export default Platform;
