import ReactGA4 from "react-ga4";


const TRACKING_ID = "G-L9E3YME0X0"


const InitializeGoogleAnalytics = () => {
  ReactGA4.initialize(TRACKING_ID);
  console.log("GA initialized");
};


const TrackPageView = (uri) => {
  console.log("GA: ", uri);
  ReactGA4.send({
    category: "TrackPageView",
    action: "trackPageView",
    page: uri,
  });
};

export { InitializeGoogleAnalytics, TrackPageView };
