import React, { useEffect } from 'react';
import ReactGA from "react-ga4";

import ContentWrapper from '../components/ContentWrapper/ContentWrapper';
import Describer from '../components/Describer/Describer';
import FeatureRow from '../components/FeatureRow/FeatureRow';
import Hero from '../components/Hero/Hero';
import { aboutUsContent } from '../constants';
import career from '../assets/images/illustrations/career.png';
import { useLocation } from 'react-router-dom';
import { TrackPageView } from '../Analytics';


const Company = () => {
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;

    if (hash) {
      const elementId = hash.replace('#', '');
      const element = document.getElementById(elementId);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }

    ReactGA.initialize("G-L9E3YME0X0");
  }, [location.hash]);


  return (
    <ContentWrapper>
      <Hero
        id={'type'}
        type="People @ Anise"
        header={'Dedicated to Improving Healthcare Payments'}
        subHeader={
          'We leverage the best of technology to ensure seamless payments experiences for Healthcare providers'
        }
        hasBtn={false}
      />
      <FeatureRow content={aboutUsContent} hasBtn={false} />
      <Describer
        header={'Help us make healthcare payments easier—for everyone'}
        description={''}
        hasBtn={true}
        btnLabel="See Open Roles"
        type={'RIGHT'}
        id={'careers'}
        image={career}
      />
    </ContentWrapper>
  );
};

export default Company;
